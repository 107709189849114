.RangeInput {
  display: flex;
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  cursor: pointer;
  position: relative;
  touch-action: none;
  /* overflow: hidden; */
}

.RangeInput--label {
  color: var(--white);
  z-index: 0;
}

/* Track */
.RangeInput--track {
  background: var(--accent-darken-2);
  border-radius: 16px;  
  height: 1rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.RangeInput-disabled .RangeInput--track {
  background: var(--white-darken);
}

.RangeInput--range {
  background: var(--accent);
  border-radius: 16px;  
  height: 1rem;
  left: var(--min-percent);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: calc(var(--max-percent) - var(--min-percent));
  z-index: 1;
}

