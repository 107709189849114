*:disabled,
*:disabled:hover,
*:disabled::placeholder {
  background: transparent;
  color: var(--white-darken-2);
  cursor: default;
}

label {
  display: inline-block;
  margin-top: 1.5rem;
}

label a {
  color: var(--accent-lighten);
  transition: 0.1s ease;
}

label a:hover {
  color: var(--white);
}

button,
input[type="text"] {
  border: 1px solid transparent;
  border-radius: 9999px;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: -0.005em;
  margin: 0;
  outline: none;
  padding: 1rem calc((1rem + 1.375rem + 1rem) / 2);
}

input[type="text"]:focus {
  border-color: var(--accent);
}

button {
  background: var(--accent-darken-2);
  color: var(--accent-lighten-2);
  cursor: pointer;
}

button:focus,
button:hover {
  background: var(--accent-darken);
}

button[data-type="icon"] {
  align-items: center;
  background: none;
  color: var(--white);
  display: inline-flex;
  height: 3.25rem;
  justify-content: center;
  padding: 0.75rem;
  width: 3.25rem;
}

button[data-type="icon"]:focus,
button[data-type="icon"]:hover {
  background: rgba(255, 255, 255, 0.1);
}

button[data-type="link"] {
  align-items: center;
  background: none;
  color: var(--white);
  display: flex;
  position: relative;
}

button[data-type="link"]:hover {
  background: none;
}

button[data-type="link"]:focus {
  background: rgba(255, 255, 255, 0.1);
}

button[data-type="link"]::after {
  content: "";
  position: absolute;
  bottom: 0.75rem;
  left: calc((1rem + 1.375rem + 1rem) / 2);
  right: calc((1rem + 1.375rem + 1rem) / 2);
  height: 1px;
  transition: 0.1s ease;
}

button[data-type="link"]:focus::after,
button[data-type="link"]:hover::after {
  background: var(--white);
}

input {
  color: var(--white);
  background: rgba(255, 255, 255, 0.1);
  caret-color: var(--white);
  width: 100%;
}

input:read-only {
  cursor: default;
}

input::placeholder {
  color: var(--accent-lighten);
}

label[data-type="error"] {
  color: rgba(255, 120, 120);
  padding: 0 calc((1rem + 1.375rem + 1rem) / 2);
  position: absolute;
  left: 0;
  bottom: -11rem;
}

.RadioInput {
  position: relative;
}

.CheckboxInput label,
.RadioInput label {
  margin: 0;
}

input[type="radio"] {
  left: -100%;
  position: fixed;
  top: -100%;
}

input[type="radio"] ~ label {
  --radio-size: calc(1.5rem + 1rem);
  cursor: pointer;
  display: block;
  line-height: var(--radio-size);
  padding-left: var(--radio-size);
  padding-right: var(--radio-size);
}

input[type="radio"] ~ label::before,
input[type="radio"] ~ label::after {
  border: 2px solid transparent;
  border-radius: 50%;
  content: "";
  display: block;
  height: 1.25rem;
  left: 0;
  margin: 0.5rem;
  position: absolute;
  top: 0px;
  width: 1.25rem;
}

input[type="radio"] ~ label::before {
  border-color: var(--accent);
}

input[type="radio"] ~ label::after {
  background: var(--accent);
  transform: scale(0);
  transition: 0.1s ease;
}

input[type="radio"]:checked ~ label::after {
  transform: scale(0.5);
}

input[type="radio"]:active ~ label,
input[type="radio"]:focus ~ label,
input[type="radio"]:hover ~ label,
input[type="checkbox"]:active ~ label,
input[type="checkbox"]:focus ~ label,
input[type="checkbox"]:hover ~ label {
  background: rgba(255, 255, 255, 0.1);
  color: var(--white);
}

.CheckboxInput {
  position: relative;
}

input[type="checkbox"] {
  height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
}

input[type="checkbox"] ~ label {
  --checkbox-size: calc(1.5rem + 1rem);
  cursor: pointer;
  display: block;
  line-height: var(--checkbox-size);
  padding-left: var(--checkbox-size);
  padding-right: var(--checkbox-size);
}

input[type="checkbox"] ~ label::before,
input[type="checkbox"] ~ label::after {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
}

input[type="checkbox"] ~ label::before {
  border: 2px solid transparent;
  content: "";
  height: 1.25rem;
  margin: 0.5rem;
  width: 1.25rem;
}

input[type="checkbox"] ~ label::after {
  height: 2.5rem;
  text-align: center;
  width: 2.5rem;
}

input[type="checkbox"] ~ label::before {
  border-color: var(--accent);
}

input[type="checkbox"] ~ label::after {
  content: "✓";
  color: var(--accent);
  font-weight: bold;
  transform: scale(0);
  transition: 0.1s ease;
}

input[type="checkbox"]:checked ~ label::after {
  transform: scale(1);
}

.SelectBox-select {
  position: relative;
}

select {
  appearance: none;
  background: none;
  border: none;
  border-bottom: 2px solid var(--accent);
  border-radius: 0;
  color: var(--white);
  cursor: pointer;
  font-size: 1rem;
  display: block;
  padding: 0.75rem;
  margin: 0.75rem 0 0;
  width: 100%;
}

.SelectBox-select::after {
  content: "▼";
  position: absolute;
  right: 0.75rem;
  top: 0.8rem;
  transform: scaleY(0.5);
}

select:focus,
.SelectBox-select:hover select {
  background: rgba(255, 255, 255, 0.1);
  outline: none;
}
