:root {
  --loader-animation: loader 1.5s infinite ease-in-out;
}

@keyframes loader {
  0% {
    transform: scaley(0.4);
  }
  33% {
    transform: scaley(1);
  }
  66% {
    transform: scaley(0.4);
  }
  100% {
    transform: scaley(0.4);
  }
}

.Loader {
  height: 2.25rem;
  opacity: 0.6;
  text-align: center;
}

.Loader--small {
  height: 1rem;
}

.Loader > div {
  animation-fill-mode: both;
  animation: var(--loader-animation);
  background-color: #fff;
  display: inline-block;
  height: 100%;
  margin: 0 0.125rem;
  transform: scaley(0.4);
  width: 0.25rem;
}

.Loader > div:nth-child(1) {
  animation-delay: 0.1s;
}
.Loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.Loader > div:nth-child(3) {
  animation-delay: 0.3s;
}
.Loader > div:nth-child(4) {
  animation-delay: 0.4s;
}
