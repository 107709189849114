.NumberInput {
  align-items: center;
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: auto 1fr;
  margin-bottom: 0.75rem;
}

.NumberInput > label {
  margin-top: 0;
}

input[type="number"] {
  border: 1px solid transparent;
  border-radius: 9999px;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: -0.005em;
  outline: none;
  padding: 0.75rem calc((0.75rem + 0.75rem) / 2);
  width: 3.125rem;
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
