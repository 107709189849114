.Configuration {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 1.5rem;
  padding: 1.5rem 0;
}

.Configuration > h3:not(:first-child) {
  margin-top: 3.75rem;
  padding-top: 3.75rem;
}
