html,
body,
#root,
.App {
  height: 100%;
}

.Page {
  overflow: auto;
}

.CurrentStep {
  background: rgb(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.75rem 1.5rem;
}
